import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table';

import {
  selectCapacityThreshold,
  selectBinSize,
  selectMaximize, selectCurrentProjectInfo,
} from 'state/workflowSlice';

import {
  PlanningTableDataExpander,
  VolumeTableData,
} from 'features/table/PlanningTableDataExpander';

import styles from 'features/table/Table.module.css';
import {
  stl_secondary_background_color,
  stl_secondary_hilited_background_color,
  stl_hilited_background_color,
  stl_hilited_text_color,
} from 'theme/cemTheme';
import { usePlanningVolumesDataProvider } from '../workflow_planning/planningVolumesCommon';

const oddHilite = stl_hilited_background_color;
const evenHilite = stl_secondary_hilited_background_color;

function hiliteCell(cell: any, capacityThreshold: number): boolean {
  const hilite =
    cell.column.id !== 'Time' && cell.getValue() < capacityThreshold;
  // if (cell.column.id !== 'Time') { console.log(`${cell.column.id}  ${cell.getValue()} < ${capacityThreshold} ${hilite}`); }
  return hilite || null;
}

export function PlanningTableVolume() {
  const capacityThreshold = useSelector(selectCapacityThreshold);
  const binSize = useSelector(selectBinSize);
  const maximize = useSelector(selectMaximize);
  const userProject = useSelector(selectCurrentProjectInfo);

  // normalize capacityThreshold to binSize
  const binCapacityThreshold = useMemo(
    () => (capacityThreshold * binSize) / 60,
    [capacityThreshold, binSize],
  );

  const columns = useMemo<MRT_ColumnDef<VolumeTableData>[]>(
    () => {
      const base = [
        {
          accessorKey: 'Time', // access nested data with dot notation
          header: 'Time',
        },

        ];
      if (!userProject?.me_day_types) {
        base.push(
          {
          accessorKey: 'Weekday', // normal accessorKey
          header: 'Weekday',
          },
          {
            accessorKey: 'Saturday',
            header: 'Saturday',
          },
          {
            accessorKey: 'Sunday',
            header: 'Sunday',
          });
      } else {
        base.push(
          {
            accessorKey: 'Weekday_SuTh', // normal accessorKey
            header: 'Weekday',
          },
          {
            accessorKey: 'Friday',
            header: 'Friday',
          },
          {
            accessorKey: 'Saturday',
            header: 'Saturday',
          }
          );
      }
      return base as MRT_ColumnDef<VolumeTableData>[];
    },
    [userProject]
  );

  // typicalData from REST api
  const planningVolumeData = usePlanningVolumesDataProvider();

  // memoize tableDataExpander
  const tableDataExpander = useMemo(
    () => new PlanningTableDataExpander(binSize, userProject?.me_day_types),
    [binSize],
  );

  // memoize expanded segmentSpeeds
  const tableData = useMemo(
    () => tableDataExpander?.expandData(planningVolumeData),
    [planningVolumeData, tableDataExpander],
  );

  // https://react-table-v7.tanstack.com/docs/examples/basic

  return (
    <div className={styles.table}>
      <MaterialReactTable
        columns={columns}
        data={tableData}
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={false}
        enableSorting={false}
        enableBottomToolbar={false}
        enableTopToolbar={false}
        muiTableBodyRowProps={{ hover: false }}
        initialState={{ density: 'compact' }}
        muiTableHeadCellProps={{
          sx: {
            '&:nth-of-type(1)': {
              borderLeft: 'none',
            },
            borderLeft: '1px solid var(--primary-text)',
            paddingLeft: '0.5rem',
          },
        }}
        muiTableBodyProps={{
          sx: {
            // stripe the rows, make odd rows a darker color
            '& tr:nth-of-type(odd)': {
              backgroundColor: stl_secondary_background_color,
            },
          },
        }}
        muiTableBodyCellProps={({ row, cell }) => ({
          // color: stl_hilited_background_color,
          sx: {
            '&:nth-of-type(1)': {
              borderLeft: 'none',
            },
            color:
              hiliteCell(cell, binCapacityThreshold) && stl_hilited_text_color,
            backgroundColor:
              hiliteCell(cell, binCapacityThreshold) &&
              (row.index % 2 ? oddHilite : evenHilite),
            borderLeft: '1px solid var(--primary-text)',
            paddingLeft: '0.5rem',
            // borderBottom: 0,
          },
        })}
      />
    </div>
  );
}
